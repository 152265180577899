import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Footer(props) {
  const { compressed } = props;

  // detect ios
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // console.log("iOS", iOS);
  return (
    <div className="footer">
      <div className="footer-list">
        <div className="container">
          <div className="row">
            {/* col-xl-4, col-xl-8 for half-footer */}
            <div
              className={classnames({
                "col-lg-3": !compressed,
                "col-xl-4": compressed,
              })}
            >
              <div className="logo-footer">
                <Link to="/" className="footer-brand">
                  <img src="/img/fonepay-logo.svg" alt="" />
                </Link>

                {/* <a
                  href="http://www.f1soft.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/img/f1-logo-new.png" className="company" alt="" />
                </a> */}
              </div>
            </div>
            <div
              className={classnames({
                "col-lg-9": !compressed,
                "col-xl-8": compressed,
              })}
            >
              <div className="footer-nav clearfix">
                <div className="list-list float-md-left">
                  <h6>About</h6>
                  <ul className="footer-list">
                    <li>
                      <a
                        href="https://www.fonepay.com/about-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.fonepay.com/contact-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://blog.fonepay.com/fees-charges/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Fees &amp; Charge
                      </a>
                    </li>
                    <li>
                      <Link to="/fonepay-points-loyalty">
                        Fonepay Points Loyalty
                      </Link>
                    </li>
                    <li>
                      <Link to="/fonepay-challenges">Fonepay Challenges</Link>
                    </li>
                  </ul>
                </div>
                <div className="list-list float-md-left">
                  <h6>Support</h6>
                  <ul className="footer-list">
                    <li>
                      <a
                        href="https://fonepay.com/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms Condition
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.fonepay.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy &amp; Policy
                      </a>
                    </li>
                    <li className="visibility-hidden">
                      <a href="#!"> &nbsp;</a>
                    </li>
                  </ul>
                </div>
                <div className="list-list float-md-left">
                  <h6>Join Us</h6>
                  <ul className="footer-list">
                    <li>
                      <a
                        href="https://www.fonepay.com/fonepay-merchant"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Become Merchant
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.fonepay.com/partners"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Partners
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.fonepay.com/users"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Users
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.fonepay.com/business"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Business
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="list-list pr-0 float-md-left ">
                  <h6>Download App</h6>
                  <ul className="footer-list">
                    <li>
                      <a
                        href="https://apps.apple.com/us/app/fonepay-offer-app/id1478083267"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="/img/ios-download-badge.png" alt="" />
                      </a>
                    </li>

                    {/* android */}
                    {!iOS && (
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.fonepay"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src="/img/en-badge-web-generic.png" alt="" />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right-footer clearfix">
        <div className="container">
          <div className="copy-link">
            <p>Copyright &copy; Fonepay Payments Services Ltd.</p>
          </div>
          <div className="copyright-media">
            <a
              href="https://www.facebook.com/fonepay/"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook media-background"
            >
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </a>
            <a
              href="https://twitter.com/fonepay"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter media-background"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
            <a
              href="https://www.instagram.com/fonepaynepal/"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram media-background"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
            {/* <a
              href='https://www.linkedin.com/company/fonepaynepal/'
              target='_blank'
              rel='noopener noreferrer'
              className='linked-in media-background'
            >
              <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export const CompressedFooter = () => (
  <div className="compressed-footer">
    <Footer compressed />
    <style jsx="true">{`
      .footer {
        display: none;
      }
      .compressed-footer .footer {
        display: block;
      }
    `}</style>
  </div>
);

export default Footer;
